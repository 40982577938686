import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { Text, Img } from 'components'; // Remplacez par la bibliothèque que vous utilisez

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Img
        className="h-[450px]  mb-8"
        src="images/tec404.png" // Remplacez par votre image 404
        alt="Not Found"
      />
      <Text
        className="text-xl md:text-3xl font-bold text-indigo-800 mb-4"
        size="txtInterBold26"
      >
        Oops! Page non trouvée
      </Text>
      <Text
        className="text-base text-center text-gray-600 mb-8"
        size="txtInterRegular16"
      >
        Désolé, la page que vous recherchez semble introuvable.
      </Text>
      <div className="bg-white-A700 border border-indigo-800 border-solid flex flex-col items-center justify-end p-2 w-[34%] md:w-full hover:bg-yellow-800">
        <div className="flex flex-row items-center justify-between w-[97%] md:w-full">
          <Link className="flex flex-row items-center justify-between w-[100%] md:w-full" to="/accueil">
            <Text
              className="text-center text-indigo-800 text-xs"
              size="txtInterBold12"
            >
              Retour à l'accueil
            </Text>
            <Img
              className="h-[10px] w-[9px]"
              src="images/img_forward.svg"
              alt="Retour à l'accueil"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
