import React from "react";
import PropTypes from "prop-types";

const shapes = { round: "rounded-[34px]", square: "rounded-none" };

const variants = {
  fill: {
    white_A700: "bg-white-A700 text-indigo-800",
    cyan_400: "bg-orange-300 shadow-bs text-white-A700",
    cyan_400_67: "bg-gray-300 text-indigo-800",
  },
};
const sizes = { xs: "p-2", sm: "px-3 py-[13px]", md: "p-[25px] sm:px-5" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["round", "square"]),
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["white_A700", "cyan_400", "cyan_400_67"]),
};

export { Button };
