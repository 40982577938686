import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
const Contact = React.lazy(() => import("pages/Contact"));
const Equipe = React.lazy(() => import("pages/Equipe"));
const Blog = React.lazy(() => import("pages/Blog"));
const Expertise = React.lazy(() => import("pages/Expertise"));
const Apropos = React.lazy(() => import("pages/Apropos"));
const Accueil = React.lazy(() => import("pages/Accueil"));
const Accueilavant = React.lazy(() => import("pages/Accueilavant"));
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/accueilavant" element={<Accueilavant />} />
          <Route path="/accueil" element={<Accueil />} />
          <Route path="/apropos" element={<Apropos />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/equipe" element={<Equipe />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
