import React from "react";

const sizeClasses = {
  txtInterBold32Cyan400: "font-bold font-inter",
  txtCinzelRomanBold32: "font-bold font-cinzel",
  txtInterRegular20: "font-inter font-normal",
  txtInterBold26: "font-bold font-inter",
  txtInterBold40: "font-bold font-inter",
  txtRobotoRegular24: "font-normal font-roboto",
  txtInterBold42: "font-bold font-inter",
  txtInterBold20: "font-bold font-inter",
  txtNirmalaUI32: "font-nirmalaui font-normal",
  txtInterBold18Black900: "font-bold font-inter",
  txtInterRegular22: "font-inter font-normal",
  txtRobotoBold26: "font-bold font-roboto",
  txtInterRegular25: "font-inter font-normal",
  txtInterRegular26: "font-inter font-normal",
  txtInterRegular28: "font-inter font-normal",
  txtRobotoRegular22: "font-normal font-roboto",
  txtInterRegular22Gray50003: "font-inter font-normal",
  txtInterBold18: "font-bold font-inter",
  txtInterBold26Black900: "font-bold font-inter",
  txtInterBold32: "font-bold font-inter",
  txtInterRegular16Cyan400: "font-inter font-normal",
  txtInterBold55: "font-bold font-inter",
  txtInterBold12: "font-bold font-inter",
  txtInterBold35: "font-bold font-inter",
  txtInterBold75: "font-bold font-inter",
  txtRobotoRegular16: "font-normal font-roboto",
  txtInterBold70: "font-bold font-inter",
  txtRobotoRomanBold28: "font-bold font-roboto",
  txtInterRegular16: "font-inter font-normal",
  txtInterRegular22Gray500: "font-inter font-normal",
  txtInterRegular18: "font-inter font-normal",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
